import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './CreateCard.css';

const negativeWords = [
    'bad', 'hate', 'ugly', 'stupid', 'dumb', 'idiot', 'racist', 'slur',
    // Add more negative words as needed
];

const CreateCard = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        location: '',
        cardType: 'police1' // Default to first police card
    });
    const [error, setError] = useState('');

    // Image URLs for the cards
    const cardImages = {
        police1: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727315086/Screenshot_2024-09-25_at_8.44.40_PM_auzw2r.png',
        police2: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727400051/Screenshot_2024-09-26_at_8.20.45_PM_ajy8pa.png',
        police3: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727400175/Screenshot_2024-09-26_at_8.22.49_PM_oglv2k.png',
        firefighter1: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727315113/Screenshot_2024-09-25_at_8.45.05_PM_rv8yz8.png',
        firefighter2: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727400065/Screenshot_2024-09-26_at_8.21.00_PM_nzrdus.png',
        firefighter3: 'https://res.cloudinary.com/dbfjwsdtf/image/upload/v1727400078/Screenshot_2024-09-26_at_8.21.14_PM_ymkgpa.png',
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        // Check for negative words in the message
        const containsNegativeWords = negativeWords.some((word) =>
            formData.message.toLowerCase().includes(word)
        );
        if (containsNegativeWords) {
            setError('Please make your message nicer. It contains words that are not allowed.');
            return;
        }

        const cardImageUrl = cardImages[formData.cardType];

        // Prepare email data
        const emailData = {
            from_name: formData.name,
            to_name: formData.email,
            message: formData.message,
            email: formData.email,
            reply_to: formData.email,
            card_image_url: cardImageUrl,  // Send the card image URL based on selection
        };

        // Send the email using EmailJS
        emailjs
            .send('service_1bf0o4r', 'template_kw0cyj9', emailData, 'hKCbXkb0f7D4VIWnj')
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    alert('Card submitted successfully!');
                },
                (error) => {
                    console.error('FAILED...', error);
                    alert('Failed to send the card. Please try again later.');
                }
            );
    };

    return (
        <section className="create-card">
            <h1 className="create-card-title">Create a Card</h1>
            <form className="create-card-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="First and Last Name"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email of the Public Service Department</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        placeholder="Enter the recipient's email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                        placeholder="Enter the location (e.g., police office, hospital)"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        placeholder="Enter your message here"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="cardType">Choose a card:</label>
                    <select name="cardType" id="cardType" value={formData.cardType} onChange={handleChange}>
                        <option value="police1">Police Officer Card 1</option>
                        <option value="police2">Police Officer Card 2</option>
                        <option value="police3">Police Officer Card 3</option>
                        <option value="firefighter1">Firefighter Card 1</option>
                        <option value="firefighter2">Firefighter Card 2</option>
                        <option value="firefighter3">Firefighter Card 3</option>
                    </select>
                </div>
                <div className="card-preview">
                    <h3>Card Preview:</h3>
                    {formData.cardType && (
                        <img
                            src={cardImages[formData.cardType]}
                            alt={`${formData.cardType} card`}
                            className="card-image-preview"
                            style={{ width: '100px', height: '100px' }} // Set the image to be 10x smaller
                        />
                    )}
                </div>
                {error && <p className="error">{error}</p>}
                <button type="submit" className="submit-button">
                    Send
                </button>
            </form>
        </section>
    );
};

export default CreateCard;
